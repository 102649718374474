import Service from '@/services/General/PersonService';
<template>
	<div>
		<s-crud
			title="Manejo de buses"
			:config="config"
			add
			edit
			remove
			@save="save($event)"
			:filter="filter"
			@EditEvent="editEvent($event)"
		>
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col class="s-col-form">
							<s-text
								label="Cod Empresa"
								v-model="props.item.BscCompany"
								:readonly="isEdit"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="DNI Conductor"
								v-model="props.item.BscDniDriver"
								:readonly="isEdit"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Nombre Conductor"
								v-model="props.item.BscNameDriver"
								:readonly="isEdit"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Brevete"
								v-model="props.item.BscLicense"
							></s-text>
						</v-col>
						
					</v-row>
					<v-row>
						<v-col class="s-col-form">
							<s-text
								label="Placa"
								v-model="props.item.BscLicencePlate"
								:readonly="isEdit"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Capacidad"
								number
								v-model="props.item.BscCapacity"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Soat"
								v-model="props.item.BscSoat"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Tarjeta de propiedad"
								v-model="props.item.BscPropertyCard"
							></s-text>
						</v-col>
						
					</v-row>
					<v-row>
						<v-col class="s-col-form">
							<s-text
								label="Revisión Técnica"
								v-model="props.item.BscTechnicalReview"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-select-definition
								label="Destino"
								:def="1379"
								add
								v-model="props.item.TypeRoute"
							></s-select-definition>
						</v-col>
						<!-- <v-col class="s-col-form">
							<s-text
								label="Área"
								:readonly="isEdit"
							></s-text>
						</v-col> -->
						<v-col class="s-col-form">
							<s-select-definition
								label="Puerta"
								:def="1116"
								:dgr="50"
								v-model="props.item.TypeDoor"
							></s-select-definition>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<s-text
								label="Costo Por Viaje"
								decimal
								v-model="props.item.BscCost"
							></s-text>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>
		
		<!-- <div>
			<h1 :href="url">Ver mapa</h1>
		</div> -->

	</div>
</template>

<script>
	import _smonitoringBusService from "@/services/Management/MonitoringBusService.js";

	export default {
		data() {
			return {
				config: {
					model: {
						BscID: "ID"
					},
					service: _smonitoringBusService,
					headers: [
						{text: "ID", value : "BscID"},
						{text: "Cod Empresa", value : "BscCompany"},
						{text: "DNI", value : "BscDniDriver"},
						{text: "Conductor", value : "BscNameDriver"},
						{text: "Brevete", value : "BscLicense"},
						{text: "Placa", value : "BscLicencePlate"},
						{text: "Capacidad", value : "BscCapacity"},
						{text: "Ruta", value : "TypeRouteName"},
						{text: "Puerta Entrada", value : "TypeDoorName"},
						{text: "Costo Viaje", value : "BscCost"},
						{text: "Soat", value : "BscSoat"},
						{text: "Tarj. Prop", value : "BscPropertyCard"},
						{text: "Rev. Técni", value : "BscTechnicalReview"},
						
					]
				},
				filter: {},
				isEdit: false,
			}
		},

		methods: {
			save(items) {
				items.UsrCreateID = this.$fun.getUserID();
				items.UsrUpdateID = this.$fun.getUserID();
				items.save();
			},

			editEvent(items)
			{
				this.isEdit = true;
			},

			
		},

		

		created () {
		},
	}
</script>

<style scoped>

</style>